<template>
  <div>
    <div class="list-group settings">
      <div class="gitlab-user-info list-group-item">
        <h3>{{ $t("page_settings--title") }}</h3>

        <div class="list-group social-settings">
          <div class="social-each">
            <label for="GitLab_UserName">Gitlab</label>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1">@</span>
              <input
                type="text"
                id="GitLab_UserName"
                class="form-control"
                :placeholder="$t('page_settings--gitlab', { social: 'Gitlab' })"
                aria-label="GitLab Username"
                aria-describedby="GitLab username"
                v-model="social.gitlab_username"
              />
            </div>
          </div>
          <div class="social-each">
            <label for="Twitter_UserName">Twitter</label>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1">@</span>
              <input
                type="text"
                id="Twitter_UserName"
                class="form-control"
                :placeholder="
                  $t('page_settings--twitter', { social: 'Twitter' })
                "
                aria-label="Twitter handle"
                aria-describedby="Twitter handle"
                v-model="social.twitter_handle"
              />
            </div>
          </div>
          <div class="social-each">
            <label for="LinkedInUrl">LinkedIn</label>
            <div class="input-group mb-3">
              <span class="input-group-text" id="basic-addon1"
                >linkedin.com/in/</span
              >
              <input
                type="text"
                id="LinkedInUrl"
                class="form-control"
                :placeholder="
                  $t('page_settings--linkedin', { social: 'Linkedin' })
                "
                aria-label="LinkInURL"
                aria-describedby="LinkedIn Url"
                v-model="social.linkedIn_url"
              />
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-success"
          @click="updateSocialSettings"
        >
          {{ $t("global_save") }}
        </button>
      </div>
      <div class="widgetSettings list-group-item">
        <h3>{{ $t("page_settings--widgetTitle") }}</h3>

        <div class="list-group widget-settings">
          <div class="form-check form-switch widget-each">
            <input
              class="form-check-input"
              type="checkbox"
              id="exploreWidgetSwitch"
              v-model="widget.showExplore"
              disabled
            />
            <label class="form-check-label" for="exploreWidgetSwitch"
              >Explore Widget</label
            >
          </div>
          <div class="form-check form-switch widget-each">
            <input
              class="form-check-input"
              type="checkbox"
              id="WolframWidgetSwitch"
              v-model="widget.showWolfram"
            />
            <label class="form-check-label" for="WolframWidgetSwitch"
              >Wolfram Widget</label
            >
          </div>
          <div class="form-check form-switch widget-each">
            <input
              class="form-check-input"
              type="checkbox"
              id="WikidataWidgetSwitch"
              v-model="widget.showWikidata"
            />
            <label class="form-check-label" for="WikidataWidgetSwitch"
              >Wikidata Widget</label
            >
          </div>
        </div>
        <button
          type="button"
          class="btn btn-success"
          @click="updateWidgetSettings"
        >
          {{ $t("global_save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import mergeNotNull from '@/plugins/mergeNotNull';

export default {
  name: 'SettingsMain',
  data() {
    return {
      social: {
        gitlab_username: null,
        twitter_handle: null,
        linkedIn_url: null,
      },
      widget: {
        showExplore: true,
        showWolfram: true,
        showWikidata: true,
        show3DViewer: true,
      },
    };
  },
  async mounted() {
    this.unsubscribe = this.$store.subscribe(async (mutation) => {
      if (mutation.type === 'setUserInfo') {
        await this.$store.dispatch('getUserSettingsFromServer');
        const settingsFromState = this.$store.state.settings;
        const currentSettings = { social: this.social, widget: this.widget };
        const mergedSettings = mergeNotNull(currentSettings, settingsFromState);

        this.social = mergedSettings.social;
        this.widget = mergedSettings.widget;
      }
    });
  },
  methods: {
    updateSocialSettings() {
      this.$store.dispatch('updateSocialSettings', this.social);
    },
    updateWidgetSettings() {
      this.$store.dispatch('updateWidgetSettings', this.widget);
    },
  },
  components: {},
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.gitlab-user-info {
}
</style>
